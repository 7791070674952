import React, { useState } from "react";
import { InputGroup, Icon, Intent } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";
import "./LookUpComponent.css";

export const LookUpComponent: React.FC = () => {
  let history = useHistory();
  const [showLookUpInput, setShowLookUpInput] = useState(false);
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && e.target.value !== "" && e.target.value > 0) {
      const editionNum = parseInt(e.target.value);
      e.target.value = "";
      history.push(`/misfitBot/${editionNum}`);
    } else if (e.key === "Enter" && e.target.value === "") {
      handleReset();
    } else if (e.target.value <= 0) {
      handleReset();
    }
  };

  const handleReset = () => {
    (document.getElementById("lookup") as HTMLInputElement).value = "";
  };

  const handleShowLookUp = () => {
    setShowLookUpInput(true);
  };

  return (
    <>
      {showLookUpInput ? (
        <InputGroup
          autoFocus
          className="edition-search  bp3-dark fadeIn"
          type="number"
          id="lookup"
          leftIcon="search"
          large={true}
          onKeyPress={handleKeyPress}
          placeholder="Enter Edition#"
        />
      ) : (
        <Icon
          onClick={handleShowLookUp}
          className="look-up-icon"
          icon="search"
          size={26}
          intent={Intent.NONE}
        />
      )}
      <span>
        <a href="https://twitter.com/MisfitBotsNFT" target="_blank">
          <img
            alt="cardano logo"
            className="social-media-logos sub-header-social"
            src={`/social-media-logos/twitter.png`}
          />
        </a>
        <a href="https://discord.gg/ghxy5UawWF" target="_blank">
          <img
            alt="cardano logo"
            className="social-media-logos sub-header-social"
            src={`/social-media-logos/discord.png`}
          />
        </a>
      </span>
    </>
  );
};

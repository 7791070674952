import React, { useEffect, useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Home.css";
import {
  HeaderComponent,
  CollageComponent,
  BuyNowComponent,
  StepperComponent,
  FAQComponent,
  RoadmapComponent,
} from "../../Components";
import { ImageConfigs } from "./ImageTileConfig";
import SearchParams from "../../utility/SearchParams";
import { ImagePath, MisfitBotPrice, PolicyId } from "../../shared/enums";
import { Toaster, Position, Intent, Icon } from "@blueprintjs/core";
import { PRE_MINTING } from "../../shared/constants";
import {
  BASE_URL,
  RESERVATION_URL,
  HEALTH_CHECK
} from "../../shared/constants";
import { apiClient } from "../../utility/Config";

const toast = Toaster.create({
  className: "top-toaster",
  position: Position.TOP,
  maxToasts: 1,
  usePortal: true,
});

export const Home: React.FC = () => {
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const [showScrollBtn, setShowSCrollBtn] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isRare, setIsRare] = useState(false);

  let history = useHistory();

  const price = MisfitBotPrice.price;
  const RARE_ICON_COLOR = 'black';
  const [buyText, setBuyText] = useState("Buy MisfitBot");

  const searchParams = SearchParams.getInstance();
  useEffect(() => {
    if(PRE_MINTING){
      toast.show({
        intent: Intent.WARNING,
        message: "Minting is temporarily unavailable. It will return shortly.",
        timeout: 0,
      });
    }

    document.title = `MisfitBots NFTs`;

    window.addEventListener("beforeunload", () => {
      searchParams.removeTokens();
    });

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("beforeunload", () => {
        searchParams.removeTokens();
      });
    };
  }, []);

  const loadBotDetailsView = (id: number) => {
    history.push(`/misfitBot/${id}`);
    // console.log(`sample-nft-id${id}`) so annoying...;
  };

  const openBuyNowDrawer = () => {
    setBuyText('Loading...');
    // Add Health Check
    apiClient
      .get(`${BASE_URL}${HEALTH_CHECK}`)
      .then((response: any) => {
        setBuyText('Buy MisfitBot');
        setOpenBuyNow(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cancel = () => {
    setOpenBuyNow(false);
  };

  const handleScroll = () => {
    if(PRE_MINTING) return false;
    const position = window.pageYOffset;
    setScrollPosition(position);
    searchParams.setScrollPos(position);

    if (position > 50) {
      setShowSCrollBtn(true);
    } else {
      setShowSCrollBtn(false);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const setRareBadge = (attribute: string) => {
    return attribute === "Red" ||
      attribute === "Blue" ||
      attribute === "Neon-Yellow"
      ? true
      : false;
  };

  return (
    <>
      <HeaderComponent />
      <BuyNowComponent cancel={cancel} open={openBuyNow} />
      <div className="wrapper">
        <div className="home-container">
          <section>
            <h1>
              Welcome to the MisfitBots NFT Project
              <div className="slogan">Where pop art meets pixels</div>
            </h1>
            <h3>What Are MisfitBots?</h3>
            <p>
              MisfitBot NFTs are colorful and iconic 24x24 pixel robot portraits
              with subtle differences, that pay homage to vintage computer graphics and the{" "}
              <a
                target="_blank"
                className="external-link"
                href="https://en.wikipedia.org/wiki/Pop_art"
              >
                pop art
              </a>{" "}
              printmaking movement of the 20th century. They are generated by an
              algorithm, and minted on the{" "}
              <a
                className="external-link"
                target="_blank"
                href="https://cardano.org/"
              >
                Cardano Blockchain
              </a>
              .
            </p>
            <p>Read the full story here..
            <a
                className="external-link"
                target="blank"
                href="https://primepixels.medium.com/misfitbots-society-manifesto-where-pixels-meet-pop-art-42991a49f2f"
              >
                {" "}
                MisfitBots Manifesto{" "}
              </a>{" "}
            </p>
          </section>
        </div>
        <div className="home-container">
          <section className="full-site">
            <div className="Gallery-images">
              <div className="Gallery-left">
                <div
                  onClick={() =>
                    loadBotDetailsView(ImageConfigs.tileSmOne.editionNum)
                  }
                  className={`Gallery-image ${ImageConfigs.tileSmOne.bgColor}`}
                >
                  <div className="Gallery-edition-top">
                    <div className="Gallery-edition-num">Edition</div>#
                    {ImageConfigs.tileSmOne.editionNum}
                    {setRareBadge(ImageConfigs.tileSmOne.bgColor) ? (
                      <div className="rare">
                        <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                      </div>
                    ) : null}
                  </div>
                  <img
                    alt="misfitBot"
                    className={`Gallery-images-img Gallery-bot`}
                    //src={`/images/bots/${ImageConfigs.tileSmOne.editionNum}.png`}
                    src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmOne.editionNum}.png`}
                  />
                </div>
                <div
                  onClick={() =>
                    loadBotDetailsView(ImageConfigs.tileSmTwo.editionNum)
                  }
                  className={`Gallery-image ${ImageConfigs.tileSmTwo.bgColor}`}
                >
                  <div className="Gallery-edition-top rare-light">
                    <div className="Gallery-edition-num rare-light">
                      Edition
                    </div>
                    #{ImageConfigs.tileSmTwo.editionNum}
                    {setRareBadge(ImageConfigs.tileSmTwo.bgColor) ? (
                   <div className="rare-light rare">
                      <Icon size={14} icon="clean" color="white" /> Rare
                 </div>
                    ) : null}
                  </div>
                  <img
                    alt="misfitBot"
                    className={`Gallery-images-img Gallery-bot`}
                    //src={`/images/bots/${ImageConfigs.tileSmTwo.editionNum}.png`}
                    src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmTwo.editionNum}.png`}
                  />
                </div>
              </div>
              <div
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileLg.editionNum)
                }
                className={`allery-image Gallery-image--primary ${ImageConfigs.tileLg.bgColor}`}
              >
                <div className="Gallery-edition-lg">
                  <div className="Gallery-edition-num-lg">Edition</div>#
                  {ImageConfigs.tileLg.editionNum}
                  {setRareBadge(ImageConfigs.tileLg.bgColor) ? (
                    <div className="rare-lg">
                      <Icon size={16} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot-lg`}
                  //src={`/images/bots/${ImageConfigs.tileLg.editionNum}.png`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileLg.editionNum}.png`}
                />
              </div>
            </div>
            <div className="Gallery-images">
              <div
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmThree.editionNum)
                }
                className={`Gallery-image ${ImageConfigs.tileSmThree.bgColor}`}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmThree.editionNum}
                  {setRareBadge(ImageConfigs.tileSmThree.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  // src={`/images/bots/${ImageConfigs.tileSmThree.editionNum}.png`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmThree.editionNum}.png`}
                />
              </div>

              <div
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmFour.editionNum)
                }
                className={`Gallery-image ${ImageConfigs.tileSmFour.bgColor}`}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmFour.editionNum}
                  {setRareBadge(ImageConfigs.tileSmFour.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  // src={`/images/bots/${ImageConfigs.tileSmFour.editionNum}.png`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmFour.editionNum}.png`}
                />
              </div>

              <div
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmFive.editionNum)
                }
                className={`Gallery-image ${ImageConfigs.tileSmFive.bgColor}`}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmFive.editionNum}
                  {setRareBadge(ImageConfigs.tileSmFive.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  // src={`/images/bots/${ImageConfigs.tileSmFive.editionNum}.png`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmFive.editionNum}.png`}
                />
              </div>
            </div>
            <div className="Gallery-images">
              <div
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmSix.editionNum)
                }
                className={`Gallery-image ${ImageConfigs.tileSmSix.bgColor}`}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmSix.editionNum}
                  {setRareBadge(ImageConfigs.tileSmSix.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  // src={`/images/bots/${ImageConfigs.tileSmSix.editionNum}.png`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmSix.editionNum}.png`}
                />
              </div>

              <div
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmSeven.editionNum)
                }
                className={`Gallery-image ${ImageConfigs.tileSmSeven.bgColor}`}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmSeven.editionNum}
                  {setRareBadge(ImageConfigs.tileSmSeven.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  // src={`/images/bots/${ImageConfigs.tileSmSeven.editionNum}.png`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmSeven.editionNum}.png`}
                />
              </div>

              <div
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmEight.editionNum)
                }
                className={`Gallery-image ${ImageConfigs.tileSmEight.bgColor}`}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmEight.editionNum}
                  {setRareBadge(ImageConfigs.tileSmEight.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  //src={`/images/bots/${ImageConfigs.tileSmEight.editionNum}.png`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmEight.editionNum}.png`}
                />
              </div>
            </div>
            <div className="collection-desc">Collection of MisfitBots</div>
          </section>

          <section className="mobile-site">
            <div className="Gallery-images">
              <div
                className={`Gallery-image ${ImageConfigs.tileSmOne.bgColor}`}
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmOne.editionNum)
                }
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmOne.editionNum}
                  {setRareBadge(ImageConfigs.tileSmOne.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmOne.editionNum}.png`}
                />
              </div>

              <div
                className={`Gallery-image ${ImageConfigs.tileSmTwo.bgColor}`}
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmTwo.editionNum)
                }
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#{" "}
                  {ImageConfigs.tileSmTwo.editionNum}
                  {setRareBadge(ImageConfigs.tileSmTwo.bgColor) ? (
                    <div className="rare-light">
                      <Icon size={14} icon="clean" color="white" /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmTwo.editionNum}.png`}
                />
              </div>

              <div
                className={`allery-image Gallery-image--primary ${ImageConfigs.tileLg.bgColor}`}
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileLg.editionNum)
                }
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileLg.editionNum}
                  {setRareBadge(ImageConfigs.tileLg.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileLg.editionNum}.png`}
                />
              </div>
            </div>

            <div className="Gallery-images">
              <div
                className={`Gallery-image ${ImageConfigs.tileSmFive.bgColor}`}
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmFive.editionNum)
                }
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmFive.editionNum}
                  {setRareBadge(ImageConfigs.tileSmFive.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmFive.editionNum}.png`}
                />
              </div>

              <div
                className={`Gallery-image ${ImageConfigs.tileSmFour.bgColor}`}
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmFour.editionNum)
                }
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmFour.editionNum}
                  {setRareBadge(ImageConfigs.tileSmFour.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmFour.editionNum}.png`}
                />
              </div>

              <div
                className={`Gallery-image ${ImageConfigs.tileSmSeven.bgColor}`}
                onClick={() =>
                  loadBotDetailsView(ImageConfigs.tileSmSeven.editionNum)
                }
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#
                  {ImageConfigs.tileSmSeven.editionNum}
                  {setRareBadge(ImageConfigs.tileSmSeven.bgColor) ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/${ImageConfigs.tileSmSeven.editionNum}.png`}
                />
              </div>
            </div>
            <div className="collection-desc">Collection of MisfitBots</div>
          </section>
        </div>

        <div className="home-container">
          <section>
            <h3>Why should I collect?</h3>
            <p>
              {" "}
              Each MisfitBot is one-of-a-kind with a unique set of attributes
              that include a{" "}
              <strong>
                Background Color, Bot color, Antenna, Vision, and Mouthpiece.{" "}
              </strong>
              All attributes have a different degree of rarity. Buying
              MisfitBots is like buying baseball cards. Building a collection
              increases your odds of getting a rare MisfitBot, and the
              collection as a whole will have its own value.{" "}
            </p>
            {/* <p>
              <strong>
                Look out for MisfitBots with these rare attributes:
              </strong>

              <li>Background Colors: Red, Blue, Neon Yellow</li>
              <li>Bot Colors: Cardano Blue, Neon Green, Dark Gray</li>
            </p> */}
            <br />
            <br />
            <br />

            <div className="Gallery-images">
              <div
                className="Gallery-image Red"
                onClick={() => loadBotDetailsView(11)}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#11
                  {setRareBadge("Red") ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/11.png`}
                />
              </div>
              <div
                className="Gallery-image Cyan"
                onClick={() => loadBotDetailsView(3)}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#3
                  {setRareBadge("Cyan") ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/3.png`}
                />
              </div>
              <div
                className="Gallery-image Yellow"
                onClick={() => loadBotDetailsView(2865)}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#2865
                  {setRareBadge("Yellow") ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/2865.png`}
                />
              </div>
            </div>

            <div className="Gallery-images">
              <div
                className="Gallery-image Neon-Yellow"
                onClick={() => loadBotDetailsView(8149)}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#8149
                  {setRareBadge("Neon-Yellow") ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/8149.png`}
                />
              </div>
              <div
                className="Gallery-image Red"
                onClick={() => loadBotDetailsView(6662)}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#6662
                  {setRareBadge("Red") ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/6662.png`}
                />
              </div>
              <div
                className="Gallery-image White"
                onClick={() => loadBotDetailsView(9860)}
              >
                <div className="Gallery-edition-top">
                  <div className="Gallery-edition-num">Edition</div>#9860
                  {setRareBadge("White") ? (
                    <div className="rare">
                      <Icon size={14} icon="clean" color={RARE_ICON_COLOR} /> Rare
                    </div>
                  ) : null}
                </div>
                <img
                  alt="misfitBot"
                  className={`Gallery-images-img Gallery-bot`}
                  src={`${ImagePath.SAMPLE_IMAGE}/9860.png`}
                />
              </div>
            </div>
            <div className="collection-desc">Collection of MisfitBots</div>
          </section>
        </div>
        <div className="home-container">
          <section>
            <h3>How do I get my hands on one?</h3>
            <p>
              A total of 10,000 unique MisfitBots were generated by an
              algorithm, the first release includes the first 1,000 MisfitBots. Once
              those are sold out, our next release will begin, and more
              MisfitBots will be available. Ownership grants you access into a
              private MisfitBots members club and enters you in a giveaway to
              win a rare unreleased Creator's Edition MisfitBot.
            </p>
            <StepperComponent />
            <br /> <br />
            <p>
              Owning a MisfitBot officially makes you a part of MisfitBots
              Society and an investor in the future of Cardano. Purchasing a MisfitBot
              during the first release will enter you in a giveaway to win a rare unreleased Creator's
              Edition MisfitBot.
              <strong> May the odds ever be in your favor!</strong>
            </p>
            <p>
              <i>
                Note: 100 MisfitBots are being withheld from the Initial Offering.
                These will be used for giveaways, rewards—and for the
                creators/developers.
              </i>
            </p>
          </section>
        </div>

        <div className="home-container">
          <section>
            <h3>Roadmap</h3>
            <p>
              As MisfitBots are sold, we’ll provide more updates to the roadmap, 
              more details on rarity, and how to build the most valuable collections.
            </p>
            <RoadmapComponent />
          </section>
        </div>

        <div className="home-container">
          <section>
            <FAQComponent />
          </section>
        </div>

        <div className="home-container">
          <section>
            <h3>Join Our Community</h3>
            <p>
              Follow us on Twitter and join our Discord Server to be a part of
              the community, MisfitBot Society. Here you can stay up-to-date and
              meet other Misfits.
            </p>
            <h4>RESOURCES</h4>
            <div className="social">
            <Icon icon="small-minus"></Icon>
              <a
                className="external-link"
                target="blank"
                href="https://discord.gg/ghxy5UawWF"
              >
                {" "}
                Join MisfitBots Society on Discord{" "}
              </a>{" "}
            </div>
            <div className="social">
               <Icon icon="small-minus"></Icon>
              <a
                className="external-link"
                target="blank"
                href="https://twitter.com/MisfitBotsNFT"
              >
                {" "}
                Follow MisfitBotsNFT on Twitter{" "}
              </a>{" "}
            </div>
            <div className="social">
              <Icon icon="small-minus"></Icon>
              <a
                className="external-link"
                target="blank"
                href="https://primepixels.medium.com/misfitbots-society-manifesto-where-pixels-meet-pop-art-42991a49f2f"
              >
                {" "}
                Read our Manifesto on Medium{" "}
              </a>{" "}
            </div>
          </section>
        </div>

        <div className="home-container">
          <section>
            <h3>Policy IDs</h3>
            <div>
            <span> 2nd 1000 MisfitBots: </span>
            <span>
              <a
                className="external-link policy-id"
                target="_blank"
                href={`https://cardanoscan.io/tokenPolicy/${PolicyId.idTwo}`}
              >
                 {PolicyId.idTwo}
              </a>  <Icon icon="rocket-slant" color="white" /> <small>Now Minting</small>
            </span>
            </div>
            <div>
            <span> 1st 1000 MisfitBots: </span>
            <span>
              <a
                className="external-link policy-id"
                target="_blank"
                href={`https://cardanoscan.io/tokenPolicy/${PolicyId.idOne}`}
              >
                 {PolicyId.idOne}
              </a>
            </span>
            </div>
            <p>
              In order to verify the authenticity of your MisfitBot, please
              validate that the asset you are buying is an authentic MisfitBot
              and not a copy.
            </p>
          </section>
        </div>
        {showScrollBtn ? (
          <div onClick={openBuyNowDrawer} className="buy-now-btn">
            {buyText}
          </div>
        ) : null} 
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./ConfirmationComponent.css";
import { ConfettiComponent } from "../index";
import data from "../../data/misfitBots.json";
import axios from "axios";
import { apiClient } from "../../utility/Config";
import { ImagePath } from "../../shared/enums";
import { Icon, IconSize } from "@blueprintjs/core";
import { BASE_URL, RESERVATION_URL, NFTS } from "../../shared/constants";
import {
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
} from "react-share";

interface confirmProps {
  resy: any;
  status: string;
}

export const ConfirmationComponent: React.FC<confirmProps> = (props) => {
  const { resy, status } = props;
  const [botData, setBotDetails] = useState<any>(data);
  const [loading, setLoading] = useState(false);
  const [misfitBots, setMisfitBots] = useState<any>([]);
  const [firstBot, setFirstBot] = useState<any>();
  let history = useHistory();

  //let misfitBots: any[] = [];

  useEffect(() => {
    const fetchNfts = () => {
      setLoading(true);
      apiClient
        .get(`${BASE_URL}${RESERVATION_URL}${NFTS}${resy}`)
        .then((response: any) => {
          console.log("inside confirmation screen", response.data[0].nfts);
          console.log("The first one", response.data[0].nfts[0]);
          setFirstBot(response.data[0].nfts[0]);
          setMisfitBots(response.data[0].nfts);
          //misfitBots = response.data[0].nfts;
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchNfts();
  }, []);

  const getBotBackgroundColor = (id: any) => {
    const result = botData.find(
      (o: any, index: number) =>
        o.name === `MisfitBot${id}` || o.name === `MisfitBot #${id}`
    );
    const bgColor = result.attributes.find(
      (item: any) => item.trait === "Background Color"
    );

    return bgColor.value;
  };

  const renderTimeoutError = () => {
    return (
      <>
        <div className="details-container">
          <h3 className="reso-expired-msg">
            Looks like the reservation has expired...
          </h3>
          <h3>Could mean one of two things. </h3>

          <h4>You did not send the payment on time. </h4>
          <p>
            If that's the case, please close the window, then click the{" "}
            <strong>Buy MisfitBot </strong>
            buttton to create a new reservation.
          </p>
          <h4>You did send the payment on time.</h4>
          <p>
            This could mean that transactions on the blockchain are taking
            longer that expected. <strong>Please be patient,</strong> check your
            wallet to see if you have received the NFT, also check{" "}
            <a
              className="external-link"
              target="_blank"
              href="https://cardanoscan.io/tokenPolicy/3d346233e10a9a8a418ed9d931f9c92b089418088d13e38c43b229b5"
            >
              Cardanoscan.
            </a>{" "}
            for the transaction.
          </p>
          <p>
            If you still have issues, or general questions, please feel free to
            reach out to us directly on Discord
          </p>
        </div>
      </>
    );
  };

  const handleDisplyingNFT = (misfitBotId: number) => {
    history.push(`/misfitBot/${misfitBotId}`);
  };

  const renderEditionNums = () => {
    return misfitBots.map((item: any) => item.id).join(", ")
  }

  const renderMisfitBots = () => {
    return (
      <>
        <ConfettiComponent />
        {firstBot && (
          <div className="first-bot">
            <div
              onClick={() => handleDisplyingNFT(firstBot.id)}
              className={`sold-bot-portrait-wrapper ${getBotBackgroundColor(
                firstBot.id
              )}`}
            >
              <img
                alt="misfitBot"
                className="sold-misfit-img sold-misfit-img-lg"
                src={`${ImagePath.SAMPLE_IMAGE}/${firstBot?.id}.png`}
              />
              <div className="middle">
                <div className="text">View!</div>
              </div>
            </div>
          </div>
        )}

        {misfitBots.length > 1 ? (
          <>
            {misfitBots.slice(1).map((bot: any, index: number) => {
              return (
                <>
                  <div className="misfitBot-container">
                    <div
                      onClick={() => handleDisplyingNFT(bot.id)}
                      className={`sold-bot-portrait-wrapper ${getBotBackgroundColor(
                        bot.id
                      )}`}
                    >
                      <img
                        alt="misfitBot"
                        className="sold-misfit-img"
                        src={`${ImagePath.SAMPLE_IMAGE}/${bot.id}.png`}
                      />
                     
                      <div className="middle">
                        <div className="text">View!</div>
                      </div>
                    </div>
                    {/* <small>Edition #{bot.id}</small> */}
                  </div>
                </>
              );
            })}
          </>
        ) : null}
   <div className="details-container">
        <h2>Congratulations!</h2>
        <p>
            You are the proud owner of {" "}
            <strong>MisfitBot Edition #{" "}{renderEditionNums()}</strong><br />
        </p>
        </div>
        <div className="details-container">
          <p>
            If you share your MisfitBot on Twitter, you will automatically be
            entered into a mid-drop giveaway, for a chance to win a rare
            MisfitBot. Click on the Twitter icon below to share your MisfitBot and join our
            {" "}
            <a className="external-link" href="https://discord.gg/H2x6pkYMsW">
              Discord
            </a>
            {" "}Server.
          </p>

          <TwitterShareButton
            url={"https://misfitbots.io"}
            title={
              "MisfitBots - Just copped my MisfitBot! Get yours ASAP and join me in the MisfitBot Society!"
            }
            hashtags={[
              "MisfitBotsNFTs",
              "MisfitBotsSociety",
              "NFTs",
              "CNFTs",
              "Cardano",
              "Blockchain",
            ]}
            className="socialMediaButton"
          >
            <TwitterIcon size={42} />
          </TwitterShareButton>
          <p>
            Check your wallet for your new NFT, also be sure to view the
            transaction on{" "}
            <a
              className="external-link"
              target="_blank"
              href="https://cardanoscan.io/tokenPolicy/3d346233e10a9a8a418ed9d931f9c92b089418088d13e38c43b229b5"
            >
              Cardanoscan.
            </a>
            <br />
            <br />
            {/* <div className="note">
        <small>
          <strong>Note:</strong> It might take 3-5 minutes for your transaction to appear.
        </small>
        </div> */}
          </p>
        </div>
      </>
    );
  };

  return (
    <div className="fade-in">
      {status === "sold" ? renderMisfitBots() : renderTimeoutError()}
    </div>
  );
};

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./LogoComponent.css";

export const LogoComponent: React.FC = () => {
  let history = useHistory();
  const [isShown, setIsShown] = useState(false);
  const handleRedirectToHome = () => {
    history.push("/");
  };
  return (
    <><img className="logo" onClick={handleRedirectToHome} src="/misfitbots-logo.png"/>
      {/* <div
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={handleRedirectToHome}
        className="logo"
      >
        {isShown ? (
          <img className="logo-icon" src="/logo-2.png" alt="ADABots Logo" />
        ) : (
          <img className="logo-icon" src="/logo-1.png" alt="ADABots Logo" />
        )}
      </div> */}
    </>
  );
};

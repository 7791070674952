export const ImageConfigs = {
        tileLg: {
            editionNum: 1369,
            bgColor: "Red"
        },
        tileSmOne: {
            editionNum: 9135,
            bgColor: "Neon-Yellow"
        },
        tileSmTwo: {
            editionNum: 6755,//263,//4243
            bgColor: "Blue"
        },
        tileSmThree: {
            editionNum: 9697,//5437,
            bgColor: "Yellow"
        },
        tileSmFour: {
            editionNum: 4441,//456,
            bgColor: "Cyan"
        },
        tileSmFive: {
            editionNum: 5145,
            bgColor: "Taupe"
        },
        tileSmSix: {
            editionNum: 6466,
            bgColor: "Green"
        },
        tileSmSeven: {
            editionNum: 3500,
            bgColor: "White"
        },
        tileSmEight: {
            editionNum: 9679,
            bgColor: "Lavender"
        },
  };
import axios from "axios";
import Tokens from "../utility/Tokens";
import {BASE_URL} from "../shared/constants";

export const apiClient = axios.create({
    baseURL: BASE_URL
  });


apiClient.interceptors.request.use(
    (config) => {
      const tokens = Tokens.getInstance();
      const jwtToken = tokens.getToken();
  
      if (jwtToken) {
            config.headers!.Authorization = `Bearer ${jwtToken}`;      
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import { Home, MisfitBotDetails, Explore, Collections, Dashboard } from "./Pages";
import SearchParams from "./utility/SearchParams";

const App: React.FC = () => {
  const searchParams = SearchParams.getInstance();
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      searchParams.removeTokens();
    });

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("beforeunload", () => {
        searchParams.removeTokens();
      });
    };
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/misfitBot/" component={Home} />
          <Route exact path="/misfitBot/:id" component={MisfitBotDetails} />
          <Route exact path="/explore" component={Explore} />
          {/*<Route path="/collections" component={Collections} />
          <Route path="/sales" component={Dashboard} /> */}
          {/* <Route path="*" component={Explore} /> */}
        </Switch>
      </Router>
    </>
  );
};

export default App;

import React from "react";
import "./FAQComponent.css";

export const FAQComponent: React.FC = () => {
  return (
    <>
      <h3>F.A.Q</h3>
      <details>
        <summary>What is an NFT?</summary>
        <p>
          A non-fungible token is a non-interchangeable unit of data stored on
          the blockchain.
        </p>
      </details>
      <details>
        <summary>How long does it take for me to receive my MisfitBot?</summary>
        <p>
          You will receive your MisfitBot in your wallet as soon as the
          transaction clears on the blockchain.
        </p>
      </details>
      <details>
        <summary>Can I select the MisfitBot I want to buy?</summary>
        <p>
          MisfitBots are minted using a Just-In-Time minting process. All 10,000
          MisfitBots will be distributed at random, on a first come first serve
          basis, to maintain fairness. May the odds forever be in your favor!
        </p>
      </details>
      <details>
        <summary>How do I view my MisfitBot after I buy?</summary>
        <p>
          You will initially see your MisfitBot on the confirmation screen, once
          your transaction clears. We encourage you to take a screenshot of your
          MisfitBot, and share it on Twitter. You will always be able to view
          your MisfitBot by clicking the search icon, entering your MisfitBot
          Edition # and clicking enter. This will load your MisfitBot's profile
          page.
        </p>
      </details>
      {/* <details>
        <summary>Are MisfitBots a good investment?</summary>
        <p>
          That is ultimately a decision for you to make. MisfitBots aren't just
          any NFT project.Each portrait pays homage to vintage computer
          graphics and the pop art printmaking movement, through the usage of
          colorful and iconic 24x24 pixel images of robot portraits. Ownership
          of a MisfitBot grants you membership to the MisfitBots Society. We
          plan to continuously add to the roadmap, grow the MisfitBot community
          and increase the awareness of this pop art project.
        </p>
      </details> */}
      <details>
        <summary>Who is behind the MisfitBots NFT Project?</summary>
        <p>
          Prime Pixels represents two passionate connoisseurs of tech and
          culture. They are the creators of the MisfitBots NFT project, and
          future Web3 projects! Look for{" "}
          <a className="external-link" href="https://discord.gg/H2x6pkYMsW">
            @The Misfit
          </a>{" "}
          ,{" "}
          <a className="external-link" href="https://discord.gg/H2x6pkYMsW">
            @PrimePixelsTrev
          </a>{" "}
          <a className="external-link" href="https://discord.gg/H2x6pkYMsW">
            @misfitjordan
          </a>{" "},
          and{" "}
          <a className="external-link" href="https://discord.gg/H2x6pkYMsW">
            @thisisw{" "}
          </a>
          throughout our Discord server.
        </p>
      </details>
    </>
  );
};

import React from "react";
import "./FooterComponent.css";
import { Icon } from "@blueprintjs/core";
import { VERSION_NUM } from "../../shared/constants";

export const FooterComponent: React.FC = () => {
  const d = new Date();
  const currYear = d.getFullYear();
  return (
    <>
      <footer>
        <div className="misfitBots-image">
          <div className="misfitBots-text">
            {/* <h1>I am John Doe</h1>
    <p>And I'm a Photographer</p>
    <button>Hire me</button> */}
          </div>
        </div>
        <div className="footer-content">
          <p>
            Made with <Icon icon="heart" color="red" /> from Washington D.C{" "}
          </p>
          <p>
            &copy; Copyright {currYear}, <strong>Prime Pixels</strong>
          </p>
          <p> Version {VERSION_NUM}</p>
          <ul>
            <li>
              <a href="https://discord.gg/ghxy5UawWF" target="_blank">
                <img
                  alt="cardano logo"
                  className="social-media-logos"
                  src={`/social-media-logos/discord.png`}
                />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/misfitbotsnft" target="_blank">
                <img
                  alt="cardano logo"
                  className="social-media-logos"
                  src={`/social-media-logos/instagram.png`}
                />
              </a>
            </li>
            <li>
              <a href="https://www.reddit.com/user/PrimePixels" target="_blank">
                <img
                  alt="cardano logo"
                  className="social-media-logos"
                  src={`/social-media-logos/reddit.png`}
                />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/MisfitBotsNFT" target="_blank">
                <img
                  alt="cardano logo"
                  className="social-media-logos"
                  src={`/social-media-logos/twitter.png`}
                />
              </a>
            </li>
          </ul>
          <img
            alt="cardano logo"
            className="cardano-logo"
            src={`/cardano-logo.png`}
          />
        </div>
      </footer>
    </>
  );
};

{
  /* <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */
}

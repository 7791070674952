import React, { useState } from "react";
import { Icon, IconSize } from "@blueprintjs/core";
import "./RoadmapComponent.css";

export const RoadmapComponent: React.FC = () => {
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const openBuyNowDrawer = () => {
    setOpenBuyNow(true);
  };
  const cancel = () => {
    setOpenBuyNow(false);
  };
  // <Icon icon="tick" color="red" />
  return (
    <>
      <div className="roadmap-stepper-container">
        <h3>Phase 1</h3>
        <p>
          We wanted to get it right from the start! Before we launched, we
          focused on building awareness of the project, community building and
          minting preparation to ensure that everything will run smoothly.
        </p>
        <br />
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
              Release of the MisfitBots Society Manifesto
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
              Launched website and Discord Server
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">Launched our pre-drop giveaway</div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
              Community building and engagement
            </div>
          </div>
        </div>
      </div>

      <div className="roadmap-stepper-container">
        <h3>Phase 2</h3>
        <p>
          We worked hard to ensure a smooth minting process during our launch.
          In this phase we plan to provide great content, more incentives, and provide
          investors with more perks.
        </p>
        <br />
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
              Official Drop - 1st 1000 MisfitBots Released
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
                MisfitBot Profile Pages - Enter Edition # in <Icon icon="search" color="gray" /> to view your MisfitBot
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">Announce first giveaway winners</div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
              Highlight early investors and provide exclusive perks
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
              Implement a bulk Just-In-Time buying/minting process
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
            <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">Introduce bi-weekly giveaways </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
            <Icon icon="tick" color="green" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">Project verification and listing on {''}
             <a  target="_blank" href="https://www.jpg.store/collection/misfitbots">jpg.store</a> {''}
            and {''} <a  target="_blank" href="https://cnft.tools/misfitbots">CNFT Tools</a>
             </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="pulse" color="gray" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
              Provide rarity values and collection arrangements
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="roadmap-circle">
              <Icon icon="pulse" color="gray" />
            </div>
          </div>
          <div>
            <div className="roadmap-title">
              MisfitBots Marketplace - connect wallet to misfitbots.io, for listing, trading, and selling
              your MisfitBots
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

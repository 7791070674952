import "./ConfettiComponent.css";

interface ConfettiProps {}
export const ConfettiComponent: React.FC<ConfettiProps> = (props) => {

return <>
<div className="confetti-container">
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
    <div className="confetti"></div>
</div>
</>
}
import React, { useState } from "react";
import { BuyNowComponent } from "../../Components";
import "./StepperComponent.css";
import { PRE_MINTING } from "../../shared/constants";

export const StepperComponent: React.FC = () => {
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const openBuyNowDrawer = () => {
    setOpenBuyNow(true);
  };
  const cancel = () => {
    setOpenBuyNow(false);
  };

  return (
    <>
      <BuyNowComponent cancel={cancel} open={openBuyNow} />
      <div className="stepper-container">
        <h3>Follow these steps, to buy MisfitBots:</h3>
        <div className="step">
          <div>
            <div className="circle">1</div>
          </div>
          <div>
            <div className="title">Purchase ADA</div>
            <div className="caption">
              Purchase ADA through cryptocurrency exchange platforms like{" "}
              <a
                target="_blank"
                className="external-link"
                href="https://www.investvoyager.com/"
              >
                Voyager
              </a>{" "}
              or{" "}
              <a
                target="_blank"
                className="external-link"
                href="https://www.coinbase.com/"
              >
                Coinbase
              </a>
              .
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="circle">2</div>
          </div>
          <div>
            <div className="title">Set up a Supported Wallet</div>
            <div className="caption">
              Set up a supported wallet like{" "}
              <a
                target="_blank"
                className="external-link"
                href="https://namiwallet.io/"
              >
                 Nami
              </a>
              ,{" "}
              <a
                target="_blank"
                className="external-link"
                href="https://daedaluswallet.io/"
              >
                  Daedalus 
              </a>
              ,{" "}
              <a
                target="_blank"
                className="external-link"
                href="https://yoroi-wallet.com/#/"
              >
                Yoroi,
              </a>
              <a
                target="_blank"
                className="external-link"
                href="https://adalite.io/"
              >
                {" "}
                Adalite
              </a>
              .
            </div>
          </div>
        </div>
        <div className="step">
          <div>
            <div className="circle">3</div>
          </div>
          <div>
            <div className="title">Fund your Supported Wallet</div>
            <div className="caption">
              Fund your supported wallet with ADA....Now you’re ready!{" "}
            </div>
          </div>
        </div>
       { PRE_MINTING ? null :(<div className="step">
          <div>
            <div className="circle">4</div>
          </div>
          <div>
            <div className="title">Buy MisfitBot!</div>
            <div className="caption">
              Click the{" "}
              <a
                href="javascript:void(0);"
                onClick={() => openBuyNowDrawer()}
                className="external-link"
              >
                Buy MisfitBot
              </a>{" "}   
              button and carefully follow the instructions!{" "}
            </div>
          </div>
        </div>)}
        {/* <div className="step">
          <div>
            <div className="circle">5</div>
          </div>
          <div>
            <div className="title">Join the Society!</div>
            <div className="caption">
              If you your transaction succe!{" "}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

interface statuses {
    IN_PROGRESS: any;//'in-progress';
    OPEN: any //'open';
    ERROR: any//'error';
    SOLD : any//'sold';
    END_OF_SALE: any //'end-of-sale';
}

export enum Locals {
    currentPage = "currPage",
    scrollPos = "currScrollPos"
}

export enum AttributeIndex {
    background = 0,
    robotColor = 1,
    head = 2,
    mouth = 3,
    eyes = 4,
    antenna = 5
}
export enum ImagePath {
    PROD = "/images/misfitbots/",
    DEV_IMAGE = "/images/default-misfitbot.png",
    SAMPLE_IMAGE = "/images/nfts"
}

export enum MisfitBotPrice {
    price = "35.730399"
}

export enum AdaWalletAddress {
    address =  "addr1v8ej54dvd20jdgh25q6jhem05082kuuruy008vxggu7mwtq83t5jl"
}

export enum PolicyId {
    idOne = "3d346233e10a9a8a418ed9d931f9c92b089418088d13e38c43b229b5",
    idTwo = "213f28dd9a2b0633384688b5513c52f2fdb4f17a82f3ecb948dc6685"
}

export enum Locals {
    ACCESS_TOKEN = "nft-secure-transaction-key",
    REFRESH_TOKEN = "end-user-portal-refresh_token"
  }

export enum Status {
    IN_PROGRESS = "in-progress",
    OPEN = "open",
    ERROR = "error",
    SOLD = "sold",
    END_OF_SALE = "end-of-sale"
}
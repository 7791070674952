import  Storage from './LocalStorageHelper';
import {Locals} from '../shared/enums';


export default class Tokens extends  Storage<Locals> {

    private static instance?: Tokens;

    public static getInstance() {
        if (!this.instance) {
          this.instance = new Tokens();
        }
    
        return this.instance;
      }

    public getToken(): string | null {
        return this.getItem(Locals.ACCESS_TOKEN);
    }

    public setToken(token: string){
        this.setItem(Locals.ACCESS_TOKEN, token);
    }

    public removeTokens(){
        this.removeItems([Locals.ACCESS_TOKEN]);
    }
}
export const PRE_MINTING = true;
export const BASE_URL = "https://nft-reservation-service.herokuapp.com/"; //"http://localhost:8000/" 
export const JWT_TOKEN_URL = "https://dev-ztj66v3t.us.auth0.com/oauth/token";
export const RESEY_URL = "resy/";
export const RESERVATION_URL ='reservation/'
export const STATUS_URL = 'status/';
export const BUNDLE = "bundle/";
export const NFT_SALES_STATS_URL = 'nft/stats';
export const NFTS = 'nfts/';
export const HEALTH_CHECK = 'health-check';
export const STATUS_DURATION = 5000; //120000;
export const AUTH_CREDENTIALS = {
        client_id: "uaOCfOT8lCyGinkojCZW9lHaY7pm091R",
        client_secret: "JhSjT-g0dABnyZ4m_afWMQZ2tda8vpZMxAn5DkOeQXUOEjGcFsrFUCrkxZZ2tuJ9",
        audience: "https://nft-project-auth",
        grant_type: "client_credentials"
};

export const SAMPLE_BOTS_EDITIONS = [1369,2865,3500,4441,6662,6466,6755,7679,8149,9135,9438,9679,9697,9860];
export const COLLECTORS_EDITION = [33,91,92,537];
export const VERSION_NUM = "2.5.0";

import Storage from "./LocalStorageHelper";
import { Locals } from "../shared/enums";

export default class SearchParams extends Storage<Locals> {
    private static instance?: SearchParams;

    public static getInstance(){
        if(!this.instance){
            this.instance = new SearchParams();
        }
        return this.instance;
    }

    public getSearchParam(){
        return this.getItem(Locals.currentPage) || '1';
    }

    public setSearchParam(limit: number){
        this.setItem(Locals.currentPage, JSON.stringify(limit));
    }

    public removeSearchParam(){
        this.removeItem(Locals.currentPage);
    }   
    
    public getScrollPos(){
        return this.getItem(Locals.scrollPos) || "";
    }

    public setScrollPos(scrollPos: any){
        this.setItem(Locals.scrollPos, JSON.stringify(scrollPos));
    }

    public removeScrollPos(){
        this.removeItem(Locals.scrollPos);
    } 

    public removeTokens(){
        this.removeItems([Locals.scrollPos, Locals.currentPage]);
    }
}
import React from "react";
import { usePagination, DOTS } from "../../utility/usePagination";
import "./PaginationComponent.css";
import {
    Button,
    ButtonGroup,
    Icon,
    FormGroup,
    InputGroup,
    Toaster,
    Popover,
    Position,
    Tooltip,
    Intent,
  } from "@blueprintjs/core";
  import SearchParams from "../../utility/SearchParams";

interface IPagination {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  onPageChange: (page: number) => any;
}
export const PaginationComponent: React.FC<IPagination> = (props: any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const searchParams = SearchParams.getInstance();

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
    searchParams.setSearchParam(currentPage + 1)
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
    searchParams.setSearchParam(currentPage - 1)
  };

  const isDisabledPrevious = () => {
    return currentPage === 1
  }

  const isDisabledNext = () => {
      return currentPage === lastPage;
  }

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className="pagination">
      <li><Button outlined={true}  intent={Intent.WARNING} text="Previous" onClick={onPrevious} disabled={isDisabledPrevious()}/></li>
      {paginationRange.map((pageNumber: any, index: number) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <li className={`page-number ${pageNumber === currentPage ? "active-page" : ""}`}
            key={index}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li><Button outlined={true} intent={Intent.WARNING} text="Next" onClick={onNext}disabled={isDisabledNext()}/></li>
    </ul>
  );
};

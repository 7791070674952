import React from "react";
import "./LoadingComponent.css";
import { Intent, SpinnerSize, Spinner } from "@blueprintjs/core";
interface ISpinnerProps {
  hasValue: boolean;
  intent?: Intent;
  size: number;
}

interface LoadProps {
  text: string;
  cssStyle?:string; 
}

export const LoadingComponent: React.FC<LoadProps>  = (props) => {
  const { text, cssStyle } = props;


  const loaderProps: ISpinnerProps = {
    hasValue: false,
    size: SpinnerSize.STANDARD,
  };

  return (
    <>
      <div className={`loading ${cssStyle}`}>
        <Spinner {...loaderProps} /> {`Loading ${text}...`}
      </div>
    </>
  );
};

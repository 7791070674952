import React from "react";
import { LogoComponent, NavigationComponent, LookUpComponent } from "..";
import "./HeaderComponent.css";

export const HeaderComponent: React.FC = () => {
  return (
    <>
    <header>
      <div className="header-container">
      <div className="logo-container">
        <LogoComponent />
      </div>
      <div className="nav-container">
        <NavigationComponent />
      </div>
      </div>
    </header>
    <div className="sub-header">
        <div className="sub-header-left">
          
        </div>
        <div className="sub-header-right">  
          <LookUpComponent/>
        </div>
      </div>
    </>
  );
};

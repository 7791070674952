import React, { useEffect, useState, useLayoutEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  LoadingComponent,
  HeaderComponent,
  TagsComponent,
} from "../../Components";
import "./MisfitBotDetails.css";
import axios from "axios";
import { Icon, Position } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import { ImagePath } from "../../shared/enums";
import { SAMPLE_BOTS_EDITIONS } from "../../shared/constants";
import {
  BASE_URL,
  NFTS,
  STATUS_URL,
  STATUS_DURATION,
  AUTH_CREDENTIALS,
  JWT_TOKEN_URL,
  COLLECTORS_EDITION,
} from "../../shared/constants";

export const MisfitBotDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [botDetails, setBotDetails] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [moreBots, setMoreBots] = useState(true);
  const [lessBots, setLessBots] = useState(true);
  const [NFTs, setNFTs] = useState([0]);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  let history = useHistory();
  const RARE_ICON_COLOR = "black";

  let soldNFTs: number[];
  const handleBack = () => {
    history.push(`/explore`);
  };

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    const loadNFTs = () => {
      const validateId = () => {
        let bots = soldNFTs;
        // console.log(bots.find(element => element === +id));
        return bots.find((element) => element === +id);
      };

      if (parseInt(id) < 1 || parseInt(id) > 10000 || isNaN(parseInt(id))) {
        handleBack();
      }
      if (typeof validateId() === "undefined") {
        history.push(`/`);
      }
      getBotData();
    };

    axios
      .get(`${BASE_URL}${NFTS}`)
      .then((res: any) => {
        const results = res.data.map((nft: any) => nft.id);
        const NFTS = results
          .concat(SAMPLE_BOTS_EDITIONS)
          .sort((a: number, b: number) => a - b);
        soldNFTs = NFTS;
        setNFTs(NFTS);
        // console.log("Here are the sold nfts...", NFTS);
        loadNFTs();
        document.title = `MisfitBot Edition #${id}`;
      })
      .catch((err) => {
        console.log(err);
      });

    const getBotById = (data: any, id: string) => {
      return data.find(
        (o: any) => o.name === `MisfitBot${id}` || o.name === `MisfitBot #${id}`
      );
    };

    const getBotData = () => {
      window.scrollTo(0, 0);
      axios
        .get("/misfitBots.json")
        .then((res) => {
          const result = getBotById(res.data, id);
          setBotDetails(result);
          setTimeout(() => {
            setLoading(false);
            checkShowMore();
            checkShowLess();
          }, 500);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  }, [setBotDetails, id]);

  const getBotBackgroundColor = (botdata: any) => {
    if (botdata) {
      const result = botdata.attributes.find(
        (item: any) => item.trait === "Background Color"
      );
      return result.value;
    }
  };

  const getBotColor = (botdata: any) =>{
    if(botdata){
      const result = botdata.attributes.find(
        (item: any) => item.trait === "Bot Color"
      );
      return result.value;
    }
  }

  const checkShowMore = () => {
    let bots = soldNFTs;
    let currentIndex: number = +id;
    let totalBots = bots.length - 1;

    if (bots.indexOf(currentIndex) === totalBots) {
      setMoreBots(false);
    } else {
      setMoreBots(true);
    }
  };

  const checkShowLess = () => {
    let currentIndex: number = +id;
    let bots = soldNFTs;
    if (bots.indexOf(currentIndex) === 0) {
      setLessBots(false);
    } else {
      setLessBots(true);
    }
  };

  const loadNextBot = () => {
    let bots = NFTs;
    let currentIndex: number = +id;
    if (bots.indexOf(currentIndex) === bots.length - 1) {
      return;
    } else {
      let nextIndex = bots[bots.indexOf(currentIndex) + 1];
      history.push(`/misfitBot/${nextIndex}`);
    }
  };

  const loadPreviousBot = () => {
    let bots = NFTs;
    let currentIndex: number = +id;
    if (bots.indexOf(currentIndex) === 0) {
      return;
    } else {
      let nextIndex = bots[bots.indexOf(currentIndex) - 1];
      history.push(`/misfitBot/${nextIndex}`);
    }
  };

  const setRareBadge = (attribute: any) => {
    const color = getBotBackgroundColor(attribute);
    const botColor = getBotColor(attribute);
    return color === "Red" ||
      color === "Blue" ||
      color === "Neon-Yellow" ||
      color === "Graphite" ||
      botColor === "Cardano-Blue" ||
      botColor === "Dark-Gray" ||
      botColor === "Shadow" ||
      botColor === "Neon-Green"
      ? true
      : false;
  };

  const darkBg = (bg: any) => {
    const color = getBotBackgroundColor(bg);
    if (color === "Blue") return true;
    if (color === "Graphite") return true;
    return false;
  };

  const isCollectorsEdition = (edition: number) => {
    return COLLECTORS_EDITION.includes(edition);
  };

  const handleIpfsLoad = (cid: string) =>{
    window.open(`https://cloudflare-ipfs.com/ipfs/${cid}`);
  }

  return (
    <>
      <HeaderComponent />
      <div className="container mb-container">
        {loading ? (
          <LoadingComponent text="MistfitBot" />
        ) : (
          <>
            <div className="fade-in">
              <div
                className={`bot-portrait-wrapper ${getBotBackgroundColor(
                  botDetails
                )}`}
              >
                {/* <div
                  className="edition"
                  style={darkBg(botDetails) ? { color: "white" } : {}}
                >
                  <div className="edition-title">
                    {isCollectorsEdition(botDetails?.edition) ? (
                      <span>Creators </span>
                    ) : null}
                    Edition
                  </div>
                  #{botDetails?.edition}
                </div> */}
                {setRareBadge(botDetails) ? (
                  <div
                    style={darkBg(botDetails) ? { color: "white" } : {}}
                    className="rare-details"
                  >
                    <Icon
                      size={14}
                      icon="clean"
                      color={darkBg(botDetails) ? "white" : RARE_ICON_COLOR}
                    />{" "}
                    Rare
                  </div>
                ) : null}
                <div className="paging">
                  {lessBots ? (
                    <div
                      className="previous-bot-btn"
                      onClick={() => loadPreviousBot()}
                    >
                      <Icon icon="chevron-left" size={45} />
                    </div>
                  ) : null}
                  <img
                    alt={`${botDetails?.image}`}
                    title={`https://cloudflare-ipfs.com/ipfs/${botDetails?.image}`}
                    className="ada-bot-img"
                    //src={`/images/bots/${botDetails?.edition}.png`}
                    src={`${ImagePath.SAMPLE_IMAGE}/${botDetails?.edition}.png`}
                    onClick={() => handleIpfsLoad(`${botDetails?.image}`)}
                  />
                  {moreBots ? (
                    <div className="next-bot-btn" onClick={() => loadNextBot()}>
                      <Icon icon="chevron-right" size={45} />
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div className="sample-disclamier"><small>*This is <strong>a Sample Edition. Not a FINAL EDITION.</strong></small></div> */}
              <div className="bot-attributes">
                {/* <h2>DNA</h2>
                <div className="dna">{botDetails?.dna}</div> */}
                  <h2 className="edition-number" style={darkBg(botDetails) ? { color: "white" } : {}}>
                  <span className="">
                    {isCollectorsEdition(botDetails?.edition) ? (
                      <span>Creators </span>
                    ) : null}
                    Edition{' '} 
                  </span>
                   #{botDetails?.edition}
                </h2>



                <h2>ATTRIBUTES</h2>
                <ul className="tags">
                  {botDetails?.attributes.map((item: any, index: number) => {
                    return item.value === "Default" ||
                      item.value === "None" ? null : (
                      <TagsComponent key={index} {...item} />
                    );
                  })}
                </ul>
                {/* <h2>Rarity</h2>
                <div></div> */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./NavigationComponent.css";
import { Button, Drawer, DrawerSize, Position, Icon } from "@blueprintjs/core";
import { useState } from "react";
import { BuyNowComponent } from "../../Components";
import { PRE_MINTING } from "../../shared/constants";
import {
  BASE_URL,
  RESERVATION_URL,
  HEALTH_CHECK
} from "../../shared/constants";
import { apiClient } from "../../utility/Config";

interface IDrawerExampleState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  hasBackdrop: boolean;
  isOpen: boolean;
  position?: Position;
  size: string;
  isCloseButtonShown: boolean;
  usePortal: boolean;
}

export const NavigationComponent: React.FC = () => {
  // assigning location var
  const location = useLocation();

  // destruction pathname from location
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [openBuyNow, setOpenBuyNow] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [buyText, setBuyText] = useState("Buy MisfitBot");

  const mobileState: IDrawerExampleState = {
    autoFocus: true,
    canEscapeKeyClose: false,
    canOutsideClickClose: true,
    enforceFocus: true,
    hasBackdrop: true,
    isOpen: isMobileMenuOpen,
    position: Position.TOP,
    size: DrawerSize.SMALL,
    isCloseButtonShown: true,
    usePortal: true,
  };

  const handleMenuOpen = () => {
    setIsMobileMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  const openBuyNowDrawer = () => {
    setBuyText('Loading...');
    // Add Health Check
    apiClient
      .get(`${BASE_URL}${HEALTH_CHECK}`)
      .then((response: any) => {
        setBuyText('Buy MisfitBot');
        setIsMobileMenuOpen(false);
        setOpenBuyNow(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const cancel = () => {
    setOpenBuyNow(false);
  };

  const displayBuyBtn = () => {
    if (PRE_MINTING) {
      return true;
    }

    if (splitLocation[1] === "misfitBot") {
      return true;
    } else {
      return false;
    }
  };

  const displayHomeBtn = () => {
    if (splitLocation[1] === "misfitBot") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="mobile-menu">
        <ul>
          <li>
            <Icon onClick={handleMenuOpen} icon="menu" size={30} />
          </li>
        </ul>
        <Drawer className="bp3-drawer-menu mobile-menu" {...mobileState}>
          <ul className="mobile-ul">
            <li>
              <Icon
                onClick={handleMenuClose}
                color="#666"
                icon="cross"
                size={30}
              />
            </li>
            {displayHomeBtn() ? (
              <li className={splitLocation[1] === "" ? "active" : ""}>
                <NavLink exact activeClassName="active" to="/">
                  Home
                </NavLink>
              </li>
            ) : null}
            {/* <li className={splitLocation[1] === "" ? "active" : ""}>
              <NavLink exact activeClassName="active" to="/">
                Home
              </NavLink>
            </li> */}
            {/* <li className={splitLocation[1] === "explore" ? "active" : ""}>
              <NavLink exact activeClassName="active" to="/explore">
                Explore
              </NavLink>
            </li>
            <li className={splitLocation[1] === "" ? "collections" : ""}>
              <NavLink exact activeClassName="active" to="/collections">
                Collections
              </NavLink>
            </li>*/}
            {displayBuyBtn() ? null : (
              <li>
                <Button
                  className="large-btn"
                  onClick={openBuyNowDrawer}
                  outlined={false}
                  large={true}
                  intent="success"
                  text={buyText}
                />
              </li>
            )} 
          </ul>
        </Drawer>
      </div>

      <div className="full-screen-menu">
        <ul>
          {displayHomeBtn() ? (
            <li className={splitLocation[1] === "" ? "active" : ""}>
              <NavLink exact activeClassName="active" to="/">
                Home
              </NavLink>
            </li>
          ) : null}
          {/* <li>
              <Button
                className="large-btn buy-btn"
                onClick={openBuyNowDrawer}
                outlined={false}
                large={true}
                intent="success"
                text="Buy MisfitBot"
                fill={true}
              />
            </li> */}
          {/*<li className={splitLocation[1] === "explore" ? "active" : ""}>
            <NavLink exact activeClassName="active" to="/explore">
              Explore
            </NavLink>
          </li>
          <li className={splitLocation[1] === "" ? "collections" : ""}>
            <NavLink exact activeClassName="active" to="/collections">
              Collections
            </NavLink>
          </li>*/}
          {displayBuyBtn() ? null : (
            <li>
              <Button
                className="large-btn buy-btn"
                onClick={openBuyNowDrawer}
                outlined={false}
                large={true}
                intent="success"
                text={buyText}
                fill={true}
              />
            </li>
          )}
        </ul>
      </div>
      <BuyNowComponent cancel={cancel} open={openBuyNow} />
    </>
  );
};

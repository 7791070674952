import React, { useEffect, useLayoutEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  LoadingComponent,
  HeaderComponent,
  PaginationComponent,
  BuyNowComponent
} from "../../Components";
import "./Explore.css";
import SearchParams from "../../utility/SearchParams";
import { Button, Icon, InputGroup, Intent, Position } from "@blueprintjs/core";
import { Popover2, Tooltip2 } from "@blueprintjs/popover2";
import data from "../../data/misfitBots.json";
import { ImagePath, MisfitBotPrice } from "../../shared/enums";
import { PRE_MINTING } from "../../shared/constants";
let PageSize = 50;
const price = MisfitBotPrice.price;

export const Explore: React.FC = () => {
  let history = useHistory();

  const searchParams = SearchParams.getInstance();
  const [loading, setLoading] = useState(false);
  const [botData, setBotDetails] = useState<any>([]);
  const [copyData, setCopydata] = useState<any>([]);
  const [edition, setEdition] = useState<any>();
  const [isSearchResults, setDisplaySearchResults] = useState(false);
  const [limit, setLimit] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showScrollBtn, setShowSCrollBtn] = useState(false);
  const [openBuyNow, setOpenBuyNow] = useState(false);

  const loadBotDetailsView = (id: number) => {
    history.push(`/misfitBot/${id}`);
  };

  const currentBotData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return botData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);

  const loaderProps = {
    text: "Collection",
  };

  useEffect(() => {
    setLoading(true);
    setBotDetails(data);
    setCopydata(data);

    setTimeout(() => {
      setLoading(false);
    },1500);
    // rename to get current page
    if (searchParams.getSearchParam()) {
      const currPage = parseInt(searchParams.getSearchParam());
      setCurrentPage(currPage);
    } else {
      setCurrentPage(1);
    }

    // if (searchParams.getScrollPos()) {
    //   const scroll = searchParams.getScrollPos();
    //   setScrollPosition(parseInt(scroll));
    //   window.scrollTo(0, parseInt(scroll));
    // }
  }, [setBotDetails, setLoading]);

  const handleReset = () => {
    setBotDetails(copyData);
    setDisplaySearchResults(false);
    (document.getElementById('search') as HTMLInputElement).value = "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value === "" ||
      parseInt(e.target.value) < 1 ||
      parseInt(e.target.value) >= 10000
    )
      return;
    setEdition(parseInt(e.target.value));
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && e.target.value !== "") {
      setEdition(parseInt(e.target.value));
      e.target.value = "";
      filterByEdition();
    } else if (e.key === "Enter" && e.target.value === "") {
      handleReset();
    } else if (
      e.target.value <= 0 ||
      e.target.value > copyData.length + 1 ||
      e.target.value > botData.length + 1
    ) {
      return;
    }
  };

  const filterByEdition = (): void => {
    let copy: any;
    (document.getElementById('search') as HTMLInputElement).value = "";
    if (botData.length === 1) {
      setBotDetails(copyData);
      copy = [...copyData];
    } else {
      copy = [...botData];
    }

    if (edition === 1 || isNaN(edition)) {
      setBotDetails(copyData);
    }

    const result = copy.find((o: any) => o.edition === edition);

    if (result) {
      setLimit(1);
      setBotDetails([result]);
      setDisplaySearchResults(true);
    } else {
      return;
    }
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    searchParams.setScrollPos(position);

    if (position > 250) {
      setShowSCrollBtn(true);
    } else {
      setShowSCrollBtn(false);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const getBotBackgroundColor = (botdata: any) => {
    if (botdata) {
      const result = botdata.attributes.find(
        (item: any) => item.trait === "Background Color"
      );
      return result.value;
    }
  };

  const openBuyNowDrawer = () => {
    setOpenBuyNow(true);
  }
  const cancel = () => {
    setOpenBuyNow(false);
  }

  const displayBuyBtn = () => {
    if(PRE_MINTING ){
      return true;
    }
     else {
      return false;
    }
  }


  return (
    <>
      <div className="app">
        <HeaderComponent />
        <BuyNowComponent cancel={cancel} open={openBuyNow}/>
        <div className="search-container">
          <span className="filter-input-wrapper">
            <InputGroup
              className="bp3-dark filter-input"
              type="number"
              id="search"
              large={false}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              placeholder="Search by Edition#..."
            />
            {isSearchResults ? (
              <Popover2 position={Position.BOTTOM}>
                <Tooltip2 content="Clear Results" position={Position.BOTTOM}>
                  <Button
                    intent={Intent.WARNING}
                    outlined={true}
                    className="filter-btns"
                    large={false}
                    onClick={handleReset}
                    // icon="reset"
                    text="Clear"
                  />
                </Tooltip2>
              </Popover2>
            ) : (
              <Popover2 position={Position.BOTTOM}>
                <Tooltip2
                  content="Search by Edition"
                  position={Position.BOTTOM}
                >
                  <Button
                    intent={Intent.WARNING}
                    outlined={true}
                    className="filter-btns"
                    large={false}
                    onClick={filterByEdition}
                    // icon="reset"
                    text="Search"
                  />
                </Tooltip2>
              </Popover2>
            )}
          </span>
          <div className="paging-container-mobile">
            <PaginationComponent
              currentPage={currentPage}
              totalCount={botData.length}
              pageSize={PageSize}
              onPageChange={(page) => {
                setLoading(true);
                handleScrollToTop();
                setTimeout(() => {
                  setCurrentPage(page);
                  searchParams.setSearchParam(page);
                  setLoading(false);
                },1500);
              }}
            />
            {isSearchResults ? (
              <div className="results-text">Found {botData.length} Bot</div>
            ) : (
              <div className="results-text">
                Now Showing <strong>{PageSize * currentPage}</strong> -{" "}
                <strong>{botData.length}</strong> Bots
              </div>
            )}
          </div>
        </div>

        {!isSearchResults ? (
          <div className="search-results fade-in">
            <div className="xx">
              {loading ? (
                <LoadingComponent {...loaderProps} />
              ) : (
                currentBotData.map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      // onClick={() => loadBotDetailsView(item.edition)}
                      className={`yy ${getBotBackgroundColor(item)}`}
                    >
                      <div className="Gallery-edition">
                        <div className="Gallery-edition-num">Edition</div>#
                        {item.edition}
                      </div>
                      <img
                        alt={`${item?.image}`}
                        title={`https://cloudflare-ipfs.com/ipfs/${item?.image}`}
                        className={`Gallery-images-img Explore-Gallery-bot`}
                        src={`/images/nfts/${item.edition}.png`}
                        //src={`${ImagePath.DEV_IMAGE}`}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        ) : (
          <div className="search-results-found fade-in">
              <div className="xx">
              {botData.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        onClick={() => loadBotDetailsView(item.edition)}
                        className={`yy ${getBotBackgroundColor(item)}`}
                      >
                        <div className="Gallery-edition">
                          <div className="Gallery-edition-num">Edition</div>#
                          {item.edition}
                        </div>
                        <img
                        alt={`${item?.image}`}
                        title={`https://cloudflare-ipfs.com/ipfs/${item?.image}`}
                        className={`Gallery-images-img Explore-Gallery-bot`}
                        src={`/images/nfts/${item.edition}.png`}
                         // src={`${ImagePath.DEV_IMAGE}`}
                        />
                      </div>
                    );
              })}
            </div>
          </div>
        )}

        {!loading ? (
          <div className="paging-container">
            <PaginationComponent
              currentPage={currentPage}
              totalCount={botData.length}
              pageSize={PageSize}
              onPageChange={(page) => {
                setLoading(true);
                handleScrollToTop();
                setTimeout(() => {
                  setCurrentPage(page);
                  searchParams.setSearchParam(page);
                  setLoading(false);
                },1500);
              }}
            />
            {isSearchResults ? (
              <div className="results-text">Found {botData.length} Bot</div>
            ) : (
              <div className="results-text">
                Now Showing <strong>{PageSize * currentPage}</strong> -{" "}
                <strong>{botData.length}</strong> Bots
              </div>
            )}
          </div>
        ) : null}
        {showScrollBtn ? (
          <>
          
          <div
            className={showScrollBtn ? "scrollToTopBtn fade-in" : ""}
            onClick={() => handleScrollToTop()}
          >
            <Icon icon="chevron-up" size={30} />
          </div>
          {/* {displayBuyBtn() ? null: <div onClick={openBuyNowDrawer} className="buy-now-btn">Buy MisfitBot</div>} */}
          </>
        ) : null}
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import "./TimerComponent.css";

interface TimerProps {
    hours: number;
    minutes: number,
    seconds:  number,
    handleUpdate: () => void,
};

export const TimerComponent: React.FC<TimerProps> = (props) => {
    const {hours, minutes, seconds, handleUpdate} = props;
    const [over, setOver] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(false);
   
    const [time, setTime] = useState({
        hours: parseInt(String(hours), 10),
        minutes: parseInt(String(minutes), 10),
        seconds: parseInt(String(seconds), 10)
    });

    const URL = 'https://github.com/vacuumlabs/adalite/wiki/Troubleshooting#my-transaction-has-been-pending-for-a-long-time--what-should-i-do';

    const tick = () => {
        // do nothing if over
        if(over) return;

        if(time.hours === 0 && time.minutes === 20 && time.seconds === 0){
            setTimeElapsed(true);
        }
        // Time up
        if(time.hours === 0 && time.minutes === 0 && time.seconds === 0){
            setOver(true);
            setTimeElapsed(false);
            handleUpdate();

        } else if (time.minutes === 0 && time.seconds === 0){
            setTime({
                hours: time.hours -1,
                minutes: 59,
                seconds: 59
            });
        } else if (time.seconds === 0 ){
            setTime({
                hours: time.hours,
                minutes: time.minutes -1,
                seconds: 59
            })
        } else {
             setTime({
                hours: time.hours,
                minutes: time.minutes,
                seconds: time.seconds - 1
             })
        }

    }

    useEffect(() => {
        let timerID = setInterval(() => tick(), 1000);

        return () => clearInterval(timerID);
    })

    return <>
      {timeElapsed ? <div> Taking too long? Read why <a target="_blank" href={URL}>here.</a></div>: null}
      <div>{over ? null : <div className="timer"><p>{hours? `${time.hours .toString().padStart(2, '0')}:` : `${time.minutes.toString().padStart(2, '0')}:${time.seconds.toString().padStart(2, '0')}`}</p></div>}</div>
    </>
}
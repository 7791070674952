import React from "react";
import "./TagsComponent.css";

export const TagsComponent: React.FC = (props: any) => {
  const { trait, value } = props;
  const parseTagVal = (tagVal: string) => {
    let cleanTag = tagVal.replaceAll('-', ' '); //capitalizeStr(tagVal.replaceAll('-', ' '));
    return cleanTag;
  }
  const capitalizeStr = (str: string) => {
    // var separateWord = str.toLowerCase().split(' ');
    // for (var i = 0; i < separateWord.length; i++) {
    //    separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
    //    separateWord[i].substring(1);
    // }
    // return separateWord.join(' ');
    return str.toUpperCase();
  }
  return (
    <li className="tag">
      {value === "Default" || value === "None" ? null : (
        <>
          <div className="tag-title">{capitalizeStr(trait)}</div> <div>{parseTagVal(value)}</div>
        </>
      )}
    </li>
  );
};

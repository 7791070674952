import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Drawer,
  DrawerSize,
  Classes,
  Position,
  FormGroup,
  InputGroup,
  Intent,
  Callout,
  Dialog,
} from "@blueprintjs/core";
import "./BuyNowComponent.css";
import { LoadingComponent } from "../../Components";
import { Tooltip2 } from "@blueprintjs/popover2";
import { AdaWalletAddress, Status } from "../../shared/enums";
import { ConfirmationComponent, TimerComponent } from "../index";
import {
  BASE_URL,
  RESERVATION_URL,
  STATUS_URL,
  STATUS_DURATION,
  HEALTH_CHECK,
  BUNDLE,
} from "../../shared/constants";
import Tokens from "../../utility/Tokens";
import { apiClient } from "../../utility/Config";

interface BuyNowDrawerState {
  autoFocus?: boolean;
  canEscapeKeyClose?: boolean;
  canOutsideClickClose?: boolean;
  enforceFocus?: boolean;
  hasBackdrop: boolean;
  isOpen: boolean;
  position?: Position;
  size?: string;
  isCloseButtonShown?: boolean;
  usePortal?: boolean;
}

interface DialogState {
  autoFocus: boolean;
  canEscapeKeyClose: boolean;
  canOutsideClickClose: boolean;
  enforceFocus: boolean;
  shouldReturnFocusOnClose: boolean;
  usePortal: boolean;
  isOpen: boolean;
}

interface BuyNowProps {
  open: boolean;
  cancel: () => void;
}

export const BuyNowComponent: React.FC<BuyNowProps> = (props) => {
  const { open, cancel } = props;
  const [price, setPrice] = useState("0");
  const [NFTsResy, setNFTsResy] = useState<any>(0);
  const [resyStatus, setRestStatus] = useState(Status.OPEN);
  const [copiedAddy, setCopiedAddy] = useState(false);
  const [copyPrice, setCopyPrice] = useState(false);
  const [copyAddyTxt, setCopyAddyTxt] = useState("Copy Address");
  const [copyPriceTxt, setCopyPriceTxt] = useState("Copy Bot Price");
  const [walletAddress, setWalletAddress] = useState(AdaWalletAddress.address);
  const [transactionComplete, setTransActionComplete] = useState(false);
  const [isSaleOver, setIsSaleOver] = useState(false);
  const [isDialogOpen, setDialogIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const tokens = Tokens.getInstance();
  const [isClipboardAvailable, setIsClipBoardAvailable] = useState(false);
  const [isQuantitySelected, setIsQuantitySelected] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [showOneHunOptions, setOneHunOption] = useState(false);

  let reseyTimerId: ReturnType<typeof setInterval>;
  let resy: number = 0;
  let resyStatusLocal: string = Status.OPEN;
  let closeBtnTxt = selectedQuantity ? "Cancel Transaction" : "Dismiss";

  const state: BuyNowDrawerState = {
    autoFocus: true,
    canEscapeKeyClose: true,
    canOutsideClickClose: false,
    enforceFocus: true,
    hasBackdrop: true,
    isOpen: open,
    position: Position.RIGHT,
    size: DrawerSize.SMALL,
    isCloseButtonShown: false,
    usePortal: true,
  };

  // callback from timer function to determine if transaction is finished
  const handleUpdate = () => {
    setTransActionComplete(true);
    killAllTimer();
  };

  const dialogState: DialogState = {
    autoFocus: true,
    canEscapeKeyClose: false,
    canOutsideClickClose: false,
    enforceFocus: true,
    shouldReturnFocusOnClose: true,
    usePortal: true,
    isOpen: isDialogOpen,
  };

  const time = {
    hours: 0,
    minutes: 30,
    seconds: 0,
    handleUpdate: handleUpdate,
  };

  const confirmProps = {
    resy: NFTsResy,
    status: resyStatus,  //'sold'
  };

  useEffect(() => {
    const isCopyPasteIsEnabled = () => {
      if (!navigator.clipboard) {
        // Clipboard API not available
        setIsClipBoardAvailable(false);
        return;
      } else {
        setIsClipBoardAvailable(true);
      }
    };

    if (open) {
      // authenticate user first
          // Add Health Check
    apiClient
    .get(`${BASE_URL}${HEALTH_CHECK}`)
    .then((response: any) => {
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
    });

      //isCopyPasteIsEnabled();
    }

    return function cleanup() {
      clearInterval(reseyTimerId);
    };
  }, [open]);

  const handleClose = () => {
    setDialogIsOpen(true);
  };

  const handleCancelTransaction = () => {
    handleUpdateReservationStatus();
  };

  const handleDismiss = () => {
    setRestStatus(Status.OPEN);
    setTransActionComplete(false);
    setIsQuantitySelected(false);
    cancel();
  };

  const killAllTimer = () => {
    let interval_id = window.setInterval(() => {}, 99999);
    for (var i = 0; i < interval_id; i++) {
      window.clearInterval(i);
    }
  };

  const copyAddressToClipBoard = () => {
    navigator.clipboard.writeText(walletAddress);
    setCopyAddyTxt("Copied");
    setTimeout(() => {
      setCopyAddyTxt("Copy Address");
    }, 3000);
  };

  const copyPriceToClipBoard = () => {
    navigator.clipboard.writeText(price);
    setCopyPriceTxt("Copied");
    setTimeout(() => {
      setCopyPriceTxt("Copy Bot Price");
    }, 3000);
  };

  const renderCopyTextBtn = (
    tooltipTxt: string,
    handleShowInputVal: () => void
  ): JSX.Element => {
    return (
      <Tooltip2 position="bottom" content={`${tooltipTxt}`}>
        <Button
          icon="duplicate"
          intent={Intent.NONE}
          minimal={true}
          onClick={handleShowInputVal}
        />
      </Tooltip2>
    );
  };

  const renderCopyTextIcon = renderCopyTextBtn(
    copyPriceTxt,
    copyPriceToClipBoard
  );

  const renderWalletAddressCopyTextIcon = renderCopyTextBtn(
    copyAddyTxt,
    copyAddressToClipBoard
  );

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  };

  const renderDialogBody = () => {
    return (
      <>
        <div className="bp3-dialog-body dialog-body">
          Are you sure you want to cancel this transaction?
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <div className="btn-spacer">
              <Button
                intent={Intent.DANGER}
                outlined={false}
                onClick={handleCancelTransaction}
              >
                Yes, Cancel
              </Button>
              <Button
                onClick={handleCloseDialog}
                intent={Intent.NONE}
                outlined={true}
              >
                Dismiss
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderProcessingTransaction = () => {
    return (
      <>
        <div className="wrapper-spinner">
          <LoadingComponent text="" cssStyle="transaction-loader" />
        </div>
        <div className="disclamer">
          <p>
            Please do not <strong>refresh</strong> this view, while the
            transaction is in progress.
          </p>
        </div>
      </>
    );
  };

  const renderErrorMessage = () => {
    return (
      <>
        <div className="details-container">
          <h3 className="reso-expired-msg">Oops, We encounted an error!</h3>
          {/* <h3>We encountered an error! </h3> */}
          <p>
            We encountered an error while processing your MisfitBot... Please
            close the window, and click the <strong>Buy MisfitBot </strong>
            button to create a new reservation.
          </p>
        </div>
      </>
    );
  };

  const renderEndOfSaleMessage = () => {
    return (
      <>
        <div className="details-container">
          <h3 className="reso-expired-msg sold-out-msg">SOLD OUT!</h3>
          {/* <h3>We encountered an error! </h3> */}
          <p>
            The first round of minting has concluded. All of the Misfitbots for
            this round have been sold. The second round will begin shortly.{" "}
          </p>
          <p>
            Please check back in a few, or stay connected with us on
            <a
              className="external-link"
              target="blank"
              href="https://twitter.com/misfitbotsnft"
            >
              {" "}
              Twitter{" "}
            </a>{" "}
            or our
            <a
              className="external-link"
              target="blank"
              href="https://discord.gg/ghxy5UawWF"
            >
              {" "}
              Discord Servers{" "}
            </a>{" "}
            for more announcements. Thank you for your interest.
          </p>
        </div>
      </>
    );
  };

  const renderTransactionInterface = () => {
    return (
      <>
        <div className="buy-now-container">
          <h3>Transaction Instructions</h3>
          <ol>
            <li>Select how many MisfitBots you would like to purchase.</li>
            <li>
              Send the <strong>Exact</strong> ADA amount displayed below to the
              wallet address displayed below. Use the QR Code or copy and paste
              or click the copy button if available for the wallet address.
            </li>
            <li>
              Please make sure your payment is complete before the timer ends.
            </li>
            <li>
              Please do not <strong>refresh</strong> the browser, while the
              transaction window is open. If you refresh the browser,{" "}
              <strong>you will lose your reservation.</strong>
            </li>
            <li>
              Once we recieve the <strong>Exact</strong> ADA, we will send you
              the NFT.
            </li>
          </ol>
          <Callout icon={null} intent={Intent.WARNING}>
            <strong>
              <p className="disclaimer-msg">
                DO NOT SEND ADA FROM AN EXCHANGE. ONLY SEND ADA FROM A SUPPORTED
                WALLET!
              </p>
            </strong>
            <h4 className="callout-header">Supported Wallets:</h4>
            <p>Nami, Daedalus, Yoroi, Adalite</p>
          </Callout>
          {renderPaymentDetailsSection()}
        </div>
      </>
    );
  };

  const renderPaymentDetailsSection = () => {
    return (
      <>
        {loading ? (
          <LoadingComponent
            text="Calculating MisfitBot"
            cssStyle="transaction-loader"
          />
        ) : isQuantitySelected ? (
          <>
            <div className="qr-code-container">
              <img
                className="wallet-qr-code"
                alt="wallet-address"
                src={`/wallet-address.png`}
              />
            </div>
            {isClipboardAvailable
              ? renderNoClipBoardPaymentInfo()
              : renderClipboardPaymentInfo()}
          </>
        ) : null}
        {isQuantitySelected ? null : renderQuantitySelectionUI()}
      </>
    );
  };

  const renderClipboardPaymentInfo = () => {
    return (
      <>
        <div className="price-info-container">
          <FormGroup
            // helperText="Send Exact ADA to this wallet address"
            label="Wallet Address"
          >
            <InputGroup
              large={true}
              name="walletAddress"
              value={walletAddress}
              disabled={true}
              leftElement={renderWalletAddressCopyTextIcon}
            />
          </FormGroup>
        </div>
        <div className="price-info-container">
          <FormGroup
            helperText="Only Send the exact amount of ADA displayed."
            label={`ADA total for ${selectedQuantity} MisfitBots`}
          >
            <InputGroup
              large={true}
              name="price"
              value={price}
              disabled={true}
              leftElement={renderCopyTextIcon}
            />
          </FormGroup>
        </div>
      </>
    );
  };

  const renderNoClipBoardPaymentInfo = () => {
    return (
      <>
        <div className="price-container">
          <div className="price-label">Wallet Address:</div>
          <div className="price-wallet-addy">{walletAddress}</div>
        </div>
        <div className="mobile-price-container">
          <div className="mobile-label">Price:</div>
          <div className="mobile-price">{price}</div>
          <small className="price-help">
            Only Send the exact amount of ADA displayed.
          </small>
        </div>

        <div className="mobile-price-container">
          <div className="mobile-label">Wallet Address:</div>
          <div className="mobile-wallet-addy">{walletAddress}</div>
        </div>
        <div className="mobile-price-container">
          <div className="mobile-label">Price:</div>
          <div className="mobile-price">{price}</div>
          <small className="price-help">
            Only Send the exact amount of ADA displayed.
          </small>
        </div>
      </>
    );
  };

  const handleMisfitBotTxt = (botAmt: number) => {
    return botAmt > 1 ? `${botAmt} MisfitBots` : `${botAmt} MisfitBot`;
  };

  const toggleHunBuy = () => {
     setOneHunOption(!showOneHunOptions);
  }
  const renderQuantitySelectionUI = () => {
    const selections = Array.from({ length: 3 }, (_, i) => (i + 1) * 3);
    // const selections = Array.from({ length: 10 }, (_, i) => i + 1);
    const list = [...new Set(selections)].map(
      (selection: number, index: number) => {
        return (
          <Button
            className="select-quant"
            intent={Intent.NONE}
            large={true}
            outlined={true}
            fill={true}
            key={index}
            onClick={() => handleQuantitySelection(selection)}
            text={handleMisfitBotTxt(selection)}
          ></Button>
        );
      }
    );
    return (
      <>
        <div className="quanity-selection-container">
          <h3>How many MisfitBots would you like to <span onClick={()=>toggleHunBuy()}>buy?</span></h3>
          <div>
            <Button
              className="select-quant"
              intent={Intent.NONE}
              large={true}
              outlined={true}
              fill={true}
              onClick={() => handleQuantitySelection(1)}
              text={handleMisfitBotTxt(1)}
            ></Button>
            {list}
            {showOneHunOptions?<Button
              className="select-quant"
              intent={Intent.NONE}
              large={true}
              outlined={true}
              fill={true}
              onClick={() => handleQuantitySelection(25)}
              text={handleMisfitBotTxt(25)}
            ></Button>: null}
          </div>
        </div>
      </>
    );
  };

  const handleQuantitySelection = (selection: any) => {
    setLoading(true);
    setSelectedQuantity(selection);

    apiClient
      .get(`${BASE_URL}${BUNDLE}${selection}`)
      .then((response: any) => {
        setPrice(formatAdaTotalAmt(response.data.ada_amount));
        resy = response.data.id;
        setNFTsResy(resy);
        reseyTimerId = setInterval(fetchResyStatus, STATUS_DURATION);
        setLoading(false);
        setIsQuantitySelected(true);
      })
      .catch((error) => {
        console.log(error);
        setRestStatus(Status.ERROR);
        setLoading(false);
      });
  };

  const fetchResyStatus = () => {
    apiClient
      .get(`${BASE_URL}${RESERVATION_URL}${STATUS_URL}${resy}`)
      .then((response: any) => {
        let status = response.data[0].status;

        setRestStatus(status);
        if (status === Status.SOLD) {
          resyStatusLocal = Status.SOLD;
        }

        if (status === Status.ERROR) {
          resyStatusLocal = Status.ERROR;
        }

        checkStatus();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdateReservationStatus = () => {
    if (NFTsResy != 0) {
      apiClient
        .put(`${BASE_URL}${RESERVATION_URL}${STATUS_URL}${NFTsResy}`, {
          status: "canceled",
        })
        .then((response: any) => {
          handleCancelUiCleanUp();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      handleCancelUiCleanUp();
    }
  };

  const handleCancelUiCleanUp = () => {
    setDialogIsOpen(false);
    setIsQuantitySelected(false);
    killAllTimer();
    cancel();
  };

  const checkStatus = () => {
    if (resyStatusLocal === Status.SOLD) {
      setTransActionComplete(true);
      clearInterval(reseyTimerId);
      //  fetchNfts();
    }
    if (resyStatusLocal === Status.ERROR) {
      clearInterval(reseyTimerId);
    }
  };

  // const fetchNfts = () => {
  //   apiClient.get(`${BASE_URL}${RESERVATION_URL}${NFTS}${resy}`).
  //   then((response: any) => {
  //     //console.log(response.data[0].nfts);
  //     setSoldNFTs(response.data[0].nfts);
  //   }).catch((error) => {
  //     console.log(error);
  //   })
  // }

  /*
    if 7 digits set the dec 1 1076893 => 1.076893
    if 8 digits set the dec 2 10581829 => 10.581829
    if 9 digits set the dec 3 105818290 = 105.818290
  */

  const formatAdaTotalAmt = (ada: number) => {
    let strADA = ada.toString();

    switch (strADA.length) {
      case 7:
        return strADA.slice(0, 1) + "." + strADA.slice(1);
      case 8:
        return strADA.slice(0, 2) + "." + strADA.slice(2);
      case 9:
        return strADA.slice(0, 3) + "." + strADA.slice(3);
      default:
        return "No Price Available";
    }
  };

  return (
    <>
      <Drawer {...state}>
        <Dialog
          icon="info-sign"
          onClose={handleCloseDialog}
          title="Cancel Transaction"
          {...dialogState}
        >
          {renderDialogBody()}
        </Dialog>
        <>
          <div className={Classes.DRAWER_BODY}>
            <div className={Classes.DIALOG_BODY}>
              {transactionComplete ? (
                <ConfirmationComponent {...confirmProps} />
              ) : resyStatus === Status.IN_PROGRESS ? (
                <>{renderProcessingTransaction()}</>
              ) : resyStatus === Status.ERROR ? (
                renderErrorMessage()
              ) : resyStatus === Status.END_OF_SALE ? (
                renderEndOfSaleMessage()
              ) : (
                renderTransactionInterface()
              )}
            </div>
          </div>
          <div className={Classes.DRAWER_FOOTER}>
            {isQuantitySelected && resyStatus === Status.OPEN ? (
              <TimerComponent {...time} />
            ) : null}
            {resyStatus === Status.END_OF_SALE ||
            transactionComplete ||
            resyStatus === Status.ERROR ? (
              <Button
                intent={Intent.PRIMARY}
                onClick={handleDismiss}
                large={true}
                fill={true}
                outlined={true}
                text="Dismiss"
              />
            ) : (
              <>
                {resyStatus === Status.IN_PROGRESS ||
                resyStatusLocal === Status.ERROR ||
                resyStatusLocal === Status.END_OF_SALE ? null : (
                  <>
                    <Button
                      intent={Intent.DANGER}
                      onClick={handleClose}
                      large={true}
                      fill={true}
                      outlined={true}
                      text="Cancel Transaction"
                    />
                  </>
                )}
              </>
            )}
          </div>{" "}
        </>
      </Drawer>
    </>
  );
};
